import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import $ from 'jquery';

// Components
import SEO from '../components/seo';
import Header from '../components/header';
import Hero from '../components/hero';
import Introduction from '../components/introduction';
import Services from '../components/services';
import Solutions from '../components/solutions';
import PriceCalculator from '../components/price-calculator';
import Contact from '../components/contact';
import Footer from '../components/footer';

// Data
import data from '../data/verein.json';
import CookieConsent from '../components/cookieConsent';

export default function Verein() {
	React.useEffect(() => {
		if (window.location.hash.length > 0) {
			window.scroll(0, 0);

			setTimeout(function () {
				window.scroll(0, 0);
			}, 1);

			$('html,body').animate(
				{
					scrollTop: $(window.location.hash).offset().top - 80 + 'px',
				},
				800,
				'swing'
			);
		}
	}, []);

	return (
		<div className="mlg_site-container mlg_page-verein">
			<SEO title="Verein" />

			<Helmet>
				<style type="text/css">{`
          .mlg_site-hero img {
            margin-bottom: -57px;
          }
          @media (max-width: 768px) {
            .mlg_site-hero img {
              margin-bottom: -138px !important;
            }
          }
          @media (max-width: 576px) {
            .mlg_site-hero img {
                margin-bottom: -71px !important;
            }
          }
          .mlg_intro {
            margin-bottom: 64px !important;
          }
          .mlg_intro .mlg_heading p {
            color: #000000;
            margin-top: 45px;
            font-size: 16px;
            line-height: 30px;
            padding-right: 0 !important;
          }
          .mlg_references {
            margin-bottom: 0 !important;
          }
        `}</style>
			</Helmet>

			<Header />

			<main className="mlg_site-content">
				<Hero {...data.hero} />

				<Introduction {...data.introduction} />

				<Services {...data.services} />

				<Solutions {...data.solutions} />

				<section
					id="sicherheit"
					className="mlg_section mlg_bg-gradient-1 mlg_has-seperator-bottom mb-1rem mb-md-95px mb-sm-38px mb-xs-38px">
					<Container className="pt-7rem pb-7rem pt-md-60px pb-md-60px pt-sm-48px pb-sm-48px pt-xs-48px pb-xs-48px">
						<Row>
							<Col lg={6} md={12}>
								<div className="mlg_heading mlg_h-white mb-md-75px mb-sm-24px mb-xs-24px">
									<h2 className="pr-xs-50px" style={{ fontSize: '50px', lineHeight: '65px' }}>Unser Schutz für Ihre Daten</h2>
									<p
										className="pr-7rem mt-md-40px pr-md-0px pr-sm-0px pr-xs-0px"
										style={{ opacity: 0.8 }}>
										THNG legt größten Wert auf die Sicherheit Ihrer Daten. Darum verbessern wir
										kontinuierlich unsere Sicherheitsvorkehrungen und garantieren den größtmöglichen
										Schutz aller Daten vor, während und nach dem Abstimmungsprozess.
									</p>
								</div>

								<p className="mb-sm-28px mb-xs-28px">
									Durch eine strikte Trennung der sensiblen Daten von den generierten Zugangscodes
									bleibt Ihre Anonymität zu jederzeit gewahrt. Somit haben wir keinen Zugriff auf
									personenbezogenen Daten, da auf unseren Servern ausschließlich die Zugangscodes
									befinden. Das verhindert eine Zuordnung der abgegebenen Stimmen zu einzelnen
									Personen. Weiterhin werden von uns keine personenbezogenen Daten auf unserem System
									gespeichert. Der Standort aller eingesetzten Server ist Deutschland. Wir sehen
									ausdrücklich vom Tracking unser Nutzer ab.
								</p>
							</Col>

							<Col lg={6} md={12} className="align-self-end">
								<p>
									Der Datenverkehr von und zu unseren Servern ist nach Stand der Technik durch den
									Einsatz von TLS v1.3 abgesichert. Auch hardwareseitig setzen wir auf zusätzliche
									Sicherheit in Form von Hardware Security Modules (HSM nach PKCS#11).
								</p>
								<p>
									Im Abstimmungsprozess wird sichergestellt, dass jeder Stimmberechtigte nur einmal
									teilnehmen kann, womit eine Mehrfachteilnahme systemseitig ausgeschlossen wird.
									Dabei ist aber nicht einsehbar, ob oder wie eine bestimmte Person bereits abgestimmt
									hat.
								</p>
								<p>
									Die Sicherheit Ihrer Daten und unserer Systeme wurde bereits erfolgreich durch einen
									mehrtägigen Penetrationstest der Firma SySS GmbH überprüft und mit einem
									Goldzertifikat ausgezeichnet. Die SySS GmbH ist ein deutsches Unternehmen mit
									Spezialisierung auf dem Gebiet der Cyber-Sicherheit und Penetrationstests. In
									Zusammenarbeit mit dem Landesbauftragten für Datenschutz und Informationssicherheit
									Baden-Württemberg, wurde ein Verfahren entwickelt, um zu gewährleisten, dass wir
									keinerlei Zugriff auf die Daten der Städte erhahlten.
								</p>
							</Col>
						</Row>
					</Container>
				</section>

				{/* <References {...data.references} /> */}

				<PriceCalculator defaultValue="Verein" />

				<Contact />
			</main>

			<Footer />

			<CookieConsent />
		</div>
	);
}
